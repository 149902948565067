<template>
    <div>
        <el-table
            ref="logging"
            :data="logs"
            :row-key="rowKey"
            :expand-row-keys="expandKeys"
            @row-click="rowClick">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <log-follow :name="scope.row"></log-follow>
                </template>
            </el-table-column>
            <el-table-column label="logfile">
                <template slot-scope="scope">
                    {{scope.row}}.log
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import api from '@/common/api';
import LogFollow from '@/components/LogFollow'

export default {
    components: {
        LogFollow
    },
    data() {
        return {
            logs: [],
            expandKeys: []
        }
    },
    mounted() {
        api.get('/logging').then(logs => {
            this.logs = logs
        })
    },
    methods: {
        rowKey(row) {
            return row
        },
        rowClick(row) {
            this.$refs.logging.toggleRowExpansion(row)
        },
    }
}
</script>